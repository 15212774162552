import { Face, Iframe } from '@haechi-labs/face-sdk';
import {
  assert,
  ConnectedWallet,
  ConnectionResult,
  invalidWallet,
  JsonRpcMethod,
  kitProvidersAndWalletsConfigError,
  LoginProviderType,
  Wallet,
  WalletMetaData,
} from '@haechi-labs/face-types';
import { ConnectorData } from '@wagmi/connectors';

import { FaceWalletConnector } from './connectors/FaceWalletConnector';
import { getFaceWallet } from './getDefaultWallets';

export type KitConfig = {
  providers?: LoginProviderType[];
  externalWalletOptions?: {
    wallets?: Wallet[];
    expanded?: boolean;
  };
};

const WALLET_CONNECT_ZINDEX = 89;
const FACE_WALLET_ZINDEX = 2147483647;
const LAST_CONNECTED_WALLET_KEY = '__face_lastConnectedWallet__';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getWalletMetaData({ createConnector, ...walletInfo }: Wallet): WalletMetaData {
  return walletInfo;
}

export class Kit {
  private face: Face;
  private iframe: Iframe;
  private config: KitConfig | undefined;
  private connectedWallet: ConnectedWallet | undefined;

  constructor(face: Face, config?: KitConfig) {
    assert(
      config?.externalWalletOptions?.wallets?.length || config?.providers?.length !== 0,
      kitProvidersAndWalletsConfigError
    );

    this.face = face;
    this.config = config;
    this.iframe = face.internal.iframe;
  }

  async connect(): Promise<ConnectedWallet> {
    // 이미 연결된 기록이 있다면, 자동 로그인
    const reconnectedWallet = await this.reconnect();
    if (reconnectedWallet) {
      return reconnectedWallet;
    }

    const { providers, externalWalletOptions } = this.config || {};
    const { wallets, expanded } = externalWalletOptions || {};
    let selectedWallet: Wallet | undefined;
    let connector;
    let result: ConnectionResult | undefined;

    const handleConnection = async (e: MessageEvent) => {
      if (e.origin !== this.iframe.iframeUrl) {
        return;
      }
      const message = e.data;
      switch (message.method) {
        case JsonRpcMethod.face_connectExternalWallet:
          const selectedWalletId = message.params?.[0]?.walletId;
          selectedWallet = wallets?.find((w) => w.id === selectedWalletId);

          if (selectedWallet) {
            // 연결할 지갑을 선택한 경우
            try {
              const isWalletConnect = selectedWallet.id.includes('walletConnect');
              const iframe = document.getElementById('face-iframe');
              if (isWalletConnect && iframe) {
                iframe.style.zIndex = `${WALLET_CONNECT_ZINDEX - 1}`;
              }
              // face.getChainId() 메소드에서 eth_chainId message 사용함
              // 로그인 안된 경우 message를 보내면 로그인페이지로 이동시키는 로직이 있어서 iframe/rpc.ts에서 eth_chainId 예외처리함
              const chainId = await this.face.getChainId();
              connector = await (selectedWallet as Wallet).createConnector();
              result = await connector.connect({
                chainId,
              });

              if (isWalletConnect && iframe) {
                iframe.style.zIndex = `${FACE_WALLET_ZINDEX}`;
              }
              await this.iframe.sendChildMessage({
                id: message.id,
                result: {
                  chain: result?.chain,
                  account: result?.account,
                } as ConnectionResult,
              });
            } catch (error) {
              await this.iframe.sendChildMessage({
                id: message.id,
                error: JSON.parse(JSON.stringify(error)),
              });
            }
          }
          break;
      }
    };
    window.addEventListener('message', handleConnection);

    try {
      const requestId = await this.iframe.sendChildMessage({
        method: JsonRpcMethod.face_openKit,
        params: [{ providers, wallets: wallets?.map(getWalletMetaData), expanded }],
      });
      const response = await this.iframe.waitForResponse<any | ConnectorData>(requestId);

      if (response?.[0]?.faceUserId) {
        const faceWalletMetaData = getWalletMetaData(await getFaceWallet({ face: this.face }));
        const connector = new FaceWalletConnector({ options: { face: this.face } });
        window.localStorage.setItem(LAST_CONNECTED_WALLET_KEY, faceWalletMetaData.id);
        this.connectedWallet = {
          ...faceWalletMetaData,
          connector,
        };
        return this.connectedWallet;
      } else if (response.account === result?.account && connector && selectedWallet) {
        window.localStorage.setItem(LAST_CONNECTED_WALLET_KEY, selectedWallet.id);
        this.connectedWallet = {
          ...getWalletMetaData(selectedWallet),
          connector,
        };
        return this.connectedWallet;
      }

      throw invalidWallet();
    } catch (e) {
      throw e;
    } finally {
      window.removeEventListener('message', handleConnection);
    }
  }

  getConnectedWallet() {
    return this.connectedWallet;
  }

  async isConnected() {
    return !!(await this.getAuthorizedWallet());
  }

  async disconnect() {
    await this.connectedWallet?.connector.disconnect();
    this.connectedWallet = undefined;
    window.localStorage.removeItem(LAST_CONNECTED_WALLET_KEY);
  }

  private async reconnect() {
    const lastConnectedWallet = await this.getAuthorizedWallet();
    if (!lastConnectedWallet) {
      return null;
    }
    const chainId = await this.face.getChainId();
    const connector = await lastConnectedWallet.connector;
    await connector.connect({
      chainId,
    });

    return lastConnectedWallet;
  }

  private async getAuthorizedWallet(): Promise<ConnectedWallet | null> {
    const lastConnectedWalletId = window.localStorage.getItem(LAST_CONNECTED_WALLET_KEY) ?? '';
    const wallets = [
      ...(this.config?.externalWalletOptions?.wallets ?? []),
      getFaceWallet({ face: this.face }),
    ];
    const lastConnectedWallet = wallets.find((w) => w.id === lastConnectedWalletId);

    if (!lastConnectedWallet || !lastConnectedWalletId || !wallets.length) {
      return null;
    }

    const connector = await lastConnectedWallet.createConnector();

    if (!(await connector.isAuthorized())) {
      return null;
    }

    this.connectedWallet = {
      ...getWalletMetaData(lastConnectedWallet),
      connector,
    };
    return this.connectedWallet;
  }
}
