import { Face } from '@haechi-labs/face-sdk';
import { Wallet } from '@haechi-labs/face-types';
import { bsc, bscTestnet, Chain, mainnet, polygon, polygonMumbai, sepolia } from '@wagmi/chains';
import { Connector } from '@wagmi/connectors';
import { WalletConnectConnector } from '@wagmi/connectors/walletConnect';
import { createStorage, noopStorage } from '@wagmi/core';

import { FaceWalletConnector } from './connectors/FaceWalletConnector';
import { isIOS, isMobile } from './utils/device';

type WalletConnectConnectorConfig = ConstructorParameters<typeof WalletConnectConnector>[0];
export type WalletConnectConnectorOptions = WalletConnectConnectorConfig['options'];

type WalletConfig = {
  chains?: Chain[];
};

const defaultChains = [bsc, bscTestnet, sepolia, mainnet, polygon, polygonMumbai];

export function setStorage(connector: Connector) {
  connector.setStorage(
    createStorage({
      storage: typeof window !== 'undefined' ? window.localStorage : noopStorage,
    })
  );
}

export function getMetaMask(config?: WalletConfig): Wallet {
  return {
    id: 'metaMask',
    name: 'MetaMask',
    iconUrl: 'https://cdn.facewallet.xyz/face-asset-common/metamask.svg',
    downloadUrls: {
      android: 'https://play.google.com/store/apps/details?id=io.metamask',
      ios: 'https://apps.apple.com/us/app/metamask/id1438144202',
      chrome: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
      firefox: 'https://addons.mozilla.org/firefox/addon/ether-metamask',
      edge: 'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm',
      opera: 'https://addons.opera.com/extensions/details/metamask-10',
      default: 'https://metamask.io/download',
    },
    createConnector: async () => {
      const { MetaMaskConnector } = await import('@wagmi/connectors/metaMask');
      class FwMetaMaskConnector extends MetaMaskConnector {
        connect() {
          // metamask 의 경우, 외부 모바일 브라우저에서는 연결을 허용하지 않습니다.
          // 상기 이유로, mobile 브라우저에서 메타마스크 연동시, 딥링크를 통해 메타마스크의 인앱브라우저에서 dapp host url 을 로딩하도록 합니다.
          if (isMobile() && !/WebView MetaMaskMobile/i.test(window.navigator.userAgent)) {
            const dappHostUri = location.href.replace(/(^\w+:|^)\/\//, '');
            const deepLink = `https://metamask.app.link/dapp/${dappHostUri}`;
            const a = document.createElement('a');
            if (isIOS()) {
              a.href = `dapp://${dappHostUri}`;
              // 앱이 설치되지 않은 경우, 차선으로 app store 로 연결합니다.
              setTimeout(function () {
                window.location.href = deepLink;
              }, 1000);
            } else {
              a.href = deepLink;
            }
            a.target = '_self';
            document.body.appendChild(a);
            a.click();
            a.remove();
            return Promise.resolve({} as any);
          }
          return super.connect();
        }
      }
      const connector = new FwMetaMaskConnector({ chains: config?.chains ?? defaultChains });
      setStorage(connector);
      return connector;
    },
  };
}

export function getWalletConnectLegacy(config?: WalletConfig): Wallet {
  return {
    id: 'walletConnectLegacy',
    name: 'WalletConnectLegacy',
    iconUrl: 'https://cdn.facewallet.xyz/face-asset-common/walletconnect.svg',
    createConnector: async () => {
      const { WalletConnectLegacyConnector } = await import(
        '@wagmi/connectors/walletConnectLegacy'
      );
      const connector = new WalletConnectLegacyConnector({
        chains: config?.chains ?? defaultChains,
        options: {
          qrcode: true,
        },
      });
      setStorage(connector);
      return connector;
    },
  };
}

export function getWalletConnect(
  config: WalletConfig & { options: WalletConnectConnectorOptions }
): Wallet {
  return {
    id: 'walletConnect',
    name: 'WalletConnect',
    iconUrl: 'https://cdn.facewallet.xyz/face-asset-common/walletconnect.svg',
    createConnector: async () => {
      const { WalletConnectConnector } = await import('@wagmi/connectors/walletConnect');
      const connector = new WalletConnectConnector({
        chains: config?.chains ?? defaultChains,
        options: config.options,
      });
      setStorage(connector);
      return connector;
    },
  };
}

export function getFaceWallet(config: WalletConfig & { face: Face }): Wallet {
  return {
    id: 'faceWallet',
    name: 'Face Wallet',
    iconUrl: 'https://cdn.facewallet.xyz/face-asset-common/faceIcon.svg',
    createConnector: async () => {
      const connector = new FaceWalletConnector({
        chains: config?.chains,
        options: { face: config.face },
      });
      setStorage(connector);
      return connector;
    },
  };
}
