export function isAndroid(): boolean {
  return typeof navigator !== 'undefined' && /android/i.test(navigator.userAgent);
}

export function isIOS(): boolean {
  return typeof navigator !== 'undefined' && /iPhone|iPod|iPad/.test(navigator.userAgent);
}

export function isMobile(): boolean {
  return isAndroid() || isIOS();
}
