import { Face, Provider } from '@haechi-labs/face-sdk';
import { FaceErrorCode, LoginProviderType } from '@haechi-labs/face-types';
import type { Chain } from '@wagmi/chains';
import { Connector } from '@wagmi/connectors';
import {
  Account,
  createWalletClient,
  custom,
  getAddress,
  ProviderRpcError,
  Transport,
  UserRejectedRequestError,
  WalletClient as WalletClient_,
} from 'viem';

export type WalletClient<
  TTransport extends Transport = Transport,
  TChain extends Chain = Chain,
  TAccount extends Account = Account
> = WalletClient_<TTransport, TChain, TAccount>;

export type FaceWalletConnectorOptions = {
  face: Face;
};

export class FaceWalletConnector extends Connector<Provider, FaceWalletConnectorOptions> {
  readonly isFaceWallet = true;
  readonly id = 'faceWallet';
  readonly name = 'Face Wallet';
  readonly ready = true;

  private provider?: Provider;

  constructor(config: { chains?: Chain[]; options: FaceWalletConnectorOptions }) {
    super(config);
  }

  get face() {
    return this.options.face;
  }

  async connect({
    chainId,
    providers,
  }: { chainId?: number; providers?: LoginProviderType[] } = {}) {
    try {
      const res = await this.face.auth.login(providers);

      let id = await this.face.getChainId();
      let unsupported = this.isChainUnsupported(id);
      if (chainId && id !== chainId) {
        const chain = await this.switchChain(chainId);
        id = chain.id;
        unsupported = this.isChainUnsupported(id);
      }

      return {
        account: res?.wallet?.address as `0x${string}`,
        chain: { id, unsupported },
      };
    } catch (error: any) {
      if (error.code === FaceErrorCode.USER_REJECTED_REQUEST) {
        throw new UserRejectedRequestError(error as Error);
      }
      throw error;
    }
  }
  async disconnect() {
    this.face.auth.logout();
  }

  async getAccount() {
    return (await this.face.getAddresses())[0] as unknown as Promise<`0x${string}`>;
  }

  async getChainId() {
    return this.face.getChainId();
  }

  async getProvider() {
    if (!this.provider) {
      this.provider = this.options.face.getEthLikeProvider();
    }
    return this.provider;
  }

  async getWalletClient({ chainId }: { chainId?: number } = {}): Promise<WalletClient> {
    const [provider, account] = await Promise.all([this.getProvider(), this.getAccount()]);
    const chain = this.chains.find((x) => x.id === chainId);
    if (!provider) throw new Error('provider is required.');
    return createWalletClient({
      account,
      chain,
      transport: custom(provider),
    });
  }

  async isAuthorized() {
    try {
      const isLoggedIn = await this.face.auth.isLoggedIn();
      if (!isLoggedIn) {
        return false;
      }
      const res = await this.face.auth.login();
      return !!res;
    } catch (e) {
      console.debug('Face Wallet is not authorized.', e);
      return false;
    }
  }

  async switchChain(chainId: number) {
    await this.options.face.switchNetwork(chainId).catch((reason) => {
      throw Error(reason);
    });

    await this.onChainChanged(chainId);
    return (
      this.chains.find((x) => x.id === chainId) ?? {
        id: chainId,
        name: `Chain ${chainId}`,
        network: '',
        nativeCurrency: { name: '', decimals: 18, symbol: '' },
        rpcUrls: { default: { http: [''] }, public: { http: [''] } },
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async watchAsset(_: { address: string; decimals?: number; image?: string; symbol: string }) {
    console.warn('Face Wallet does not support watchAsset.');
    return false;
  }

  protected onAccountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) this.emit('disconnect');
    else {
      this.emit('change', {
        account: getAddress(accounts[0] as string),
      });
    }
  };

  protected onChainChanged = async (chainId: number | string) => {
    const id = typeof chainId === 'number' ? chainId : await this.face.getChainId();

    const unsupported = this.isChainUnsupported(id);
    this.emit('change', { chain: { id, unsupported } });
  };

  protected onDisconnect = async (error: Error) => {
    if ((error as ProviderRpcError).code === 1013) {
      const provider = await this.getProvider();
      if (provider) {
        const isAuthorized = await this.getAccount();
        if (isAuthorized) return;
      }
    }

    this.emit('disconnect');
  };

  protected isUserRejectedRequestError(error: unknown) {
    return (error as ProviderRpcError).code === 4001;
  }
}
